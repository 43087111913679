import axios from "axios";
import { AUTH_TOKEN } from "constants/AuthConstant";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  // baseURL: ` https://7a68-2405-201-2002-e0f2-6a0d-389e-1d50-8186.ngrok-free.app`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `${localStorage.getItem(AUTH_TOKEN)}`,
  },
  // withCredentials: true, // Include credentials if needed
});

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { axiosInstance } from "store/hook";

export const AdminData = createAsyncThunk("contact/AdminData", async (data) => {
  // const res = await axiosInstance.get(`/admin/user-details`);
  const res = await axios.post(`${process.env.REACT_APP_API_URL}admin/user-details`, 
    data,
    {
    headers: {
      Authorization: `${localStorage.getItem("auth_token")}`,
    },
  });
  return res?.data;
});

export const AdminDataDetails = createAsyncThunk("contact/AdminDataDetails", async (data) => {
  // const res = await axiosInstance.get(`/admin/user-details/get/${data}`);
  const res = await axios.get(`${process.env.REACT_APP_API_URL}admin/user-details/get/${data}`, {
    headers: {
      Authorization: `${localStorage.getItem("auth_token")}`,
    },
  });
  return res?.data;
});

export const AdminDataCard = createAsyncThunk("contact/adminDataCard", async (data) => {
  // const res = await axiosInstance.get(`/admin/users-statistics`, data);
const res = await axios.post(
  `${process.env.REACT_APP_API_URL}admin/users-statistics`,
  data,
  {
    headers: {
      Authorization: ` ${localStorage.getItem("auth_token")}`,
    },
  }
);
  return res?.data;
});

export const UserListCategories = createAsyncThunk("contact/UserListCategories", async (data) => {
  // const res = await axiosInstance.get(`/auth/organization/contact_categories?orgId=${data}`);
  const res = await axios.get(`${process.env.REACT_APP_API_URL}auth/organization/contact_categories?orgId=${data}`, {
    headers: {
      Authorization: `${localStorage.getItem("auth_token")}`,
    },
  });
  return res?.data;
});

const initialState = {
  data: [],
  filteredList: [],
  isLoading: false,
  isComposeEmailSentLoading: false,
  uploadContactsLoading: false,
  isAttachToSeqeunceLoading: false,
  error: "",
  scheduledEmail: [],
  filteredEmail: [],
  adminResponse: [],
  adminDataCard: [],
  adminDataCount: [],
  adminDataResponse: [],
  listCategories: [],
};

export const inboxplusDashboardSlice = createSlice({
  name: "inboxplus",
  initialState,
  reducers: {
    getContacts: (state) => {
      state.isLoading = true;
    },

    filterContactsBySearch: (state, action) => {
      let finalResult = [];

      const searchStr = action?.payload.toString().toLowerCase();
      if (searchStr) {
        finalResult = state?.data?.filter((ele) => {
          return (
            ele?.first_name?.toString().toLowerCase().includes(searchStr) ||
            ele?.last_name?.toString().toLowerCase().includes(searchStr) ||
            ele?.email?.toString().toLowerCase().includes(searchStr)
          );
        });
      } else {
        finalResult = state?.data;
      }
      state.filteredList = finalResult;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(AdminData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(AdminData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.adminResponse = action.payload?.body;
      state.adminDataCount = action.payload?.count;
    });
    builder.addCase(AdminData.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(AdminDataDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(AdminDataDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.adminDataResponse = action.payload?.body;
    });
    builder.addCase(AdminDataDetails.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(AdminDataCard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(AdminDataCard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.adminDataCard = action.payload?.body;
    });
    builder.addCase(AdminDataCard.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(UserListCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UserListCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.listCategories = action.payload?.departments;
    });
    builder.addCase(UserListCategories.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { getContacts, filterContactsBySearch, filterScheduledEmail } = inboxplusDashboardSlice.actions;
export default inboxplusDashboardSlice.reducer;

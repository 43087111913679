import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import { axiosInstance } from "store/hook";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
};

export const signIn = createAsyncThunk("auth/signIn", async (loginFormData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post("/admin/login", loginFormData);
    if (response.status === 200 && response.data.success == 1) {
      localStorage.setItem(AUTH_TOKEN, response.data.body.token);
      // axiosInstance.defaults.headers.common["Authorization"] = `${response.data.body.token}`;
      return response.data.body.token;
    } else {
      return rejectWithValue(response.message?.replace("Firebase: ", ""));
    }
  } catch (err) {
    return rejectWithValue(err.message || "Error");
  }
});

export const signOut = createAsyncThunk("auth/signOut", async () => {
  const response = await FirebaseService.signOutRequest();
  localStorage.removeItem(AUTH_TOKEN);
  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      // state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      });
  },
});

export const { authenticated, showAuthMessage, hideAuthMessage, signOutSuccess, showLoading, signInSuccess } = authSlice.actions;

export default authSlice.reducer;
